@import 'styles/utils/variables';

span.searchInput {
  border-radius: 3px;
  color: $text-color-placeholder;
  border: 0.5px solid $border-color-input !important;

  & input[type='text'] {
    &::placeholder {
      color: $text-color-placeholder;
      font-size: 1.4rem;
    }
  }

  &[class*='ant-input-affix-wrapper-focused'],
  &:hover,
  &:focus {
    border-color: $border-color-primary-hover !important;
  }
}
